import React, { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FiSun, FiMoon } from "react-icons/fi";
import { useTheme } from "../context/themes/ThemeContext";

const AppBar: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState<string>("");
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateToSection = (section: string) => {
    setActiveSection(section);
    navigate(`/?v=${section}`);
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItemClass = (section: string) => `
    px-3 py-2 text-lg font-medium transition duration-300 cursor-pointer
    ${
      activeSection === section
        ? "border-l-2 lg:border-l-0 lg:border-b-2 border-cyan-500 text-cyan-500"
        : "text-gray-700  text-gray-700 dark:text-gray-300 hover:text-cyan-500 hover:border-l-2 hover:lg:border-l-0 hover:lg:border-b-2 hover:border-cyan-500 "
    }

  `;

  return (
    <header
      className={`sticky top-0 w-full z-50 transition-all duration-500 text-gray-700 dark:bg-gray-800  dark:text-gray-300 ease-in-out ${
        isScrolled
          ? "bg-transparent"
          : " bg-white shadow border-b-0 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-gray-400"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center space-x-4">
          <img
            className={`bg-contain p-2 ${
              theme === "dark"
                ? "dark-classname h-28 w-32 object-contain"
                : "light-classname h-28 w-32 object-contain"
            }`}
            src={theme === "dark" ? "/logo_w.png" : "/awail.png"}
            alt="Logo"
            loading="lazy"
          />
          </div>
          <nav className="hidden sm:flex space-x-6 justify-center flex-grow">
            <a
              href="https://documenter.getpostman.com/view/18114527/2sAXjDfG66"
              className="text-gray-700 dark:text-gray-300 hover:text-cyan-500 px-3 py-2 text-lg font-medium transition duration-300"
            >
              APIs
            </a>
            <div
              className={navItemClass("aboutus")}
              onClick={() => navigateToSection("aboutus")}
            >
              عنا
            </div>

            <div
              className={navItemClass("pricing")}
              onClick={() => navigateToSection("pricing")}
            >
              الأسعار
            </div>
            <div
              className={navItemClass("services")}
              onClick={() => navigateToSection("services")}
            >
              خدماتنا
            </div>
            <div
              className={navItemClass("home")}
              onClick={() => navigateToSection("home")}
            >
              الرئيسية
            </div>
          </nav>
          <div className="hidden sm:flex items-center space-x-4">
            <div className="flex justify-end gap-4">
              <div>
                <button
                  onClick={toggleTheme}
                  className="p-2 rounded-full focus:outline-none focus:ring-offset-2"
                  aria-label="Toggle Dark Mode"
                >
                  {theme === "dark" ? (
                    <FiSun size={24} className="text-amber-300"/>
                  ) : (
                    <FiMoon size={24} />
                  )}
                </button>
              </div>
              <div>
                {" "}
                <Link
                  to="/login"
                  className="flex items-center font-bold bg-[#30aaf0] mb-1 text-gray-100 hover:bg-[#3caff1]  px-6 py-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                >
                  تسجيل الدخول
                </Link>
              </div>
            </div>
          </div>
          <div className="block lg:hidden mx-2">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 mt-2 rounded-md bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 ring-1 ring-offset-1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              {isOpen ? (
                <FaTimes className="h-6 w-6" aria-hidden="true" />
              ) : (
                <FaBars className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 bg-white dark:bg-gray-800  text-gray-700 dark:text-gray-300 z-40"
          onClick={toggleMenu}
        ></div>
      )}

      <div
        className={`fixed top-0 left-0 h-full w-full dark:bg-gray-800 text-gray-700 dark:text-gray-300 shadow-md transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out sm:hidden z-50`}
      >
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
          <img
            className={`bg-contain p-2 ${
              theme === "dark"
                ? "dark-classname h-16 w-32 object-contain"
                : "light-classname h-16 w-32 object-contain"
            }`}
            src={theme === "dark" ? "/logo_w.png" : "/awail.png"}
            alt="Logo"
            loading="lazy"
          />
          <div>
            <button
              onClick={toggleTheme}
              className="p-2 rounded-full focus:outline-none focus:ring-offset-2"
              aria-label="Toggle Dark Mode"
            >
              {theme === "dark" ? <FiSun className="text-amber-300" size={24} /> : <FiMoon size={24} />}
            </button>
          </div>
          <button
            onClick={toggleMenu}
            className="dark:bg-gray-800 text-gray-700 dark:text-gray-300 focus:outline-none"
          >
            <FaTimes
              className="h-6 w-6 bg-red-400 p-1 rounded-full text-white"
              aria-hidden="true"
            />
          </button>
        </div>

        <div className="px-8 pt-4 pb-3 space-y-1">
          <div
            className={navItemClass("home")}
            onClick={() => navigateToSection("home")}
          >
            الرئيسية
          </div>
          <div
            className={navItemClass("pricing")}
            onClick={() => navigateToSection("pricing")}
          >
            الأسعار
          </div>
          <div
            className={navItemClass("services")}
            onClick={() => navigateToSection("services")}
          >
            خدماتنا
          </div>
          <div
            className={navItemClass("aboutus")}
            onClick={() => navigateToSection("aboutus")}
          >
            عنا
          </div>
          <a
            href="https://documenter.getpostman.com/view/18114527/2sAXjDfG66"
            className="text-gray-700 dark:text-gray-300 block px-3 py-2 text-base font-medium hover:bg-gray-100 rounded-md transition duration-300"
          >
            APIs
          </a>
        </div>
        <div className="sm:flex absolute top-96 h-svh justify-center items-center mx-4">
          <Link
            to="/login"
            className="flex items-center justify-centerfont-bold bg-[#30aaf0] mb-1 hover:bg-[#3caff1]  px-6 py-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            تسجيل الدخول
          </Link>
        </div>
      </div>
    </header>
  );
};

export default AppBar;
