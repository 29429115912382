// src/components/HeroComponent.tsx

import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const HeroComponent: React.FC = () => {
  
  return (
    <div className="relative   dark:bg-gray-800 text-gray-700 dark:text-gray-300 overflow-hidden">
      {/* Particles */}
      <div className="container mx-auto flex flex-col items-center justify-center h-screen px-6 text-center relative z-10">
        <img
          src="/awailandmore.png"
          alt="Logo"
          className="w-fit max-w-md mb-2"
        />
        <p className="text-2xl md:text-2xl font-bold lg:text-3xl mb-8">
          Business API خدمة رسائل واتساب
        </p>
        <p className="text-lg md:text-xl lg:text-2xl mb-12 max-w-4xl">
          استمتع بالتواصل مع عملائك بشكلٍ جديد تماماً عبر منصتنا الفريدة، حيث
          نجمع بين واجهة برمجة التطبيقات وقوة تطبيق واتساب. اكتشف الآن خدمة
          الرسائل المميزة والمجانية عبر واتساب واستفد منها بشكلٍ كامل! مع هذه
          الخدمة، يُسهل عليك إرسال رسائل نصية ووسائط متعددة لعملائك بدون تعقيد.
          سواء كنت تدير شركة ناشئة أو مؤسسة مُنشأة، فإن خدمتنا مصممة خصيصاً
          لتلبية احتياجاتك التسويقية وتتناسب مع ميزانيتك بكفاءة وفاعلية لتحقيق
          أهدافك بنجاح
        </p>
        <Link
          to="dashboard"
          className="flex items-center font-bold bg-[#30aaf0] mb-8 text-white hover:bg-[#40b8fd] px-8 py-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          <FaWhatsapp className="mr-2 font-bold h-6 w-6" />
          ابدأ الآن
        </Link>
      </div>
    </div>
  );
};

export default HeroComponent;
