// src/components/MediaComponent.tsx
import React from "react";
import MediaMessage from "./MediaMessage";
import { AiFillFileImage } from "react-icons/ai";

const MediaComponent: React.FC = () => {
  return (
    <div className="justify-end container mt-14">
        <div className="flex justify-end gap-3 mx-16 text-lg mt-8">
        <span>ارسال رسائل ملفات</span>
        <AiFillFileImage size={25} className="text-cyan-600"/>
        </div>
      <MediaMessage />
    </div>
  );
};

export default MediaComponent;
