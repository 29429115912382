import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
// import { format } from "date-fns";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Value as DateTimePickerValue } from "react-datetime-picker/dist/cjs/shared/types";

interface TimePickerProps {
  initialValue?: DateTimePickerValue;
  onChange: (value: DateTimePickerValue) => void;
}

const TimePickerComponent: React.FC<TimePickerProps> = ({ initialValue = new Date(), onChange }) => {
  const [value, setValue] = useState<DateTimePickerValue>(initialValue);

  const handleChange = (newValue: DateTimePickerValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  // const formatDate = (date: Date | null) => {
  //   if (date) {
  //     return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS");
  //   }
  //   return '';
  // };

  // console.log(formatDate(value instanceof Date ? value : null));

  return (
    <div className="w-full">
      <DateTimePicker
        onChange={handleChange}
        value={value}
        className="react-datetime-picker border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300 ease-in-out"
      />
    </div>
  );
};

export default TimePickerComponent;
