import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import axios from "axios";
import { DeviceMasterContext } from "../devices/DeviceMasterContext";
import { AuthContext } from "../AuthContext";

interface ContactContextType {
  ContactsData: any[] | null;
  loading: boolean;
  fetchGroupMaster: () => void;
}

export const ContactContext = createContext<ContactContextType>({
  ContactsData: null,
  loading: false,
  fetchGroupMaster: () => {},
});

const ContactsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { deviceData } = useContext(DeviceMasterContext);
  const [ContactsData, setContactsData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { userId } = useContext(AuthContext);

  const fetchGroupMaster = useCallback(async () => {
    if (!deviceData || !deviceData.data || !userId) return;

    const authenticatedDevices = deviceData.data.filter(
      (device: any) =>
        device.status === "AUTHENTICATED" && device.id_users === userId
    );

    if (authenticatedDevices.length === 0) {
      console.log("No authenticated devices found");
      return;
    }

    setLoading(true);
    try {
      const allContactsData = await Promise.all(
        authenticatedDevices.map(async (device: any) => {
          const { deviceId, Token: deviceToken } = device;
          
          try {
            const response = await axios.post(
              "https://whats1.sky.sky4system.com/api/group/getContactList",
              { deviceId, deviceToken }
            );
            // console.log(response.data.data)
            return response.data;
          } catch (error) {
            console.log(`Failed to fetch contacts for device ${deviceId}, error`);
            return null;
          }
        })
      );

      const validContactsData = allContactsData.filter((data) => data !== null);

      setContactsData(validContactsData.flat());
    } catch (error) {
      console.log("Failed to fetch Contacts data");
    } finally {
      setLoading(false);
    }
  }, [deviceData, userId]);

  useEffect(() => {
    fetchGroupMaster();
  }, [fetchGroupMaster]);

  return (
    <ContactContext.Provider value={{ ContactsData, loading, fetchGroupMaster }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactsProvider;
