import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { DeviceMasterContext } from "../../../context/devices/DeviceMasterContext";
import { DeviceOrderCotext } from "../../../context/devices/DeviceOrederContext";
import { DevicePeriodContextCotext } from "../../../context/devices/DevicePeriodContext";

const OrderPage: React.FC = () => {
  const { deviceData } = useContext(DeviceMasterContext);
  const { orderData, loading  } = useContext(DeviceOrderCotext);
  const { peroidData } = useContext(DevicePeriodContextCotext);
  // const [isloading, setisLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();

  const itemsPerPage = 4;

  const [filters, setFilters] = useState({
    name: "",
    phone: "",
  });

  const sortUsersByDate = (data: any[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB.getTime() - dateA.getTime();
    });
  };

  useEffect(() => {
    setTimeout(() => {
      // setisLoading(false);
    }, 300);
  }, [location.search, orderData]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = (data: any[]) => {
    return data.filter((order) => {
      //   const matchesName = order.name.toLowerCase().includes(filters.name.toLowerCase());
      const matchesPhone = order.phone.includes(filters.phone);
      return matchesPhone;
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return (
      <div className="grid justify-center py-6 mt-10 my-4">
        <FaSpinner className="animate-spin text-blue-500" size={28} />
      </div>
    );
  }

  const sortedUsers = sortUsersByDate(orderData?.data ?? []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredUsers = applyFilters(sortedUsers);
  const currentOrders = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

  const getDeviceName = (deviceId: string) => {
    if (!deviceData || !deviceData.data) {
      return "No Device Data";
    }
    const device = deviceData.data.find(
      (d: { deviceId: string }) => d.deviceId === deviceId
    );

    return device ? device.name : "Unknown Device";
  };

  const getPeroidMonth = (periode_id: string) => {
    if (!peroidData || !peroidData.data) {
      return "No Device Data";
    }
    const order = peroidData.data.find(
      (d: { id: string }) => d.id === periode_id
    );

    return order ? order.name : "Unknown Device";
  };

  return (
    <div className="px-4 min-h-screen my-4 mt-10">
      <div className="bg-white rounded-sm md max-h-screen px-0 scrollbar-lightr">
        <div>
          <div className="hidden lg:block" dir="rtl">
            <div className="flex flex-col lg:flex-row gap-4 mb-6 px-4">
              <input
                type="text"
                name="phone"
                placeholder="search"
                value={filters.phone}
                onChange={handleFilterChange}
                className="p-2 mt-4 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
              />
            </div>
            {orderData?.data?.length ? (
              <>
                <table className="w-11/12 divide-y divide-gray-200">
                  <thead className="bg-white border-b border-gray-300">
                    <tr>
                      <th className="px-4 py-4 font-bold text-right text-sm text-gray-500 uppercase tracking-wider">
                        الجهاز
                      </th>
                      <th className="px-4 py-4 font-bold text-right text-sm text-gray-500 uppercase tracking-wider">
                        الباقة
                      </th>
                      <th className="px-4 py-4 font-bold text-right text-sm text-gray-500 uppercase tracking-wider">
                        رقم الهاتف
                      </th>
                      <th className="px-4 py-4 font-bold text-right text-sm text-gray-500 uppercase tracking-wider">
                        حالة الطلب
                      </th>
                      <th className="px-4 py-4 font-bold text-right text-sm text-gray-500 uppercase tracking-wider">
                        المدة
                      </th>
                      <th className="px-4 py-4 font-bold text-right text-md text-gray-500 uppercase tracking-wider">
                        تاريخ الطلب
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 min-h-screen">
                    {currentOrders.map((order: any) => (
                      <tr key={order.id}>
                        <td className="px-4 w-8 py-4 font-bold whitespace-nowrap text-sm text-gray-500">
                          {/* {order.DeviceId} */}
                          {getDeviceName(order.DeviceId)}
                        </td>
                        <td className="px-4 py-4 w-10 whitespace-nowrap text-sm text-gray-500">
                          <span className="text-blue-800 font-bold">${order.amount}</span>
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                          {order.phone}
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`inline-flex px-3 py-1 text-xs font-semibold leading-5 rounded-full ${
                              order.status === "pendding"
                                ? "bg-red-100 text-red-800"
                                : "bg-blue-100 text-blue-800"
                            }`}
                          >
                            {order.status === "pendding"
                              ? "قيد الانتظار"
                              : "ٌتم التأكيد"}
                          </span>
                        </td>
                        <td className="px-4 w-8 py-4 font-normal whitespace-nowrap text-sm text-gray-500">
                          {getPeroidMonth(order.periode_id)}
                        </td>
                        <td className="px-4 py-4 whitespace-nowrap text-sm">
                          {new Intl.DateTimeFormat("ar-EG", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          }).format(new Date(order.updated_at))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="px-4 py-4 text-center text-gray-600">
                {/* <div>لا توجد بيانات</div> */}
                <div className="px-4 py-4 text-center text-gray-600">
                  <div className="flex justify-center items-center">
                    {/* <FaSpinner className="animate-spin h-5 w-5 mr-3 text-gray-500" /> */}
                    <span>No Data</span>
                  </div>
                </div>
              </div>
            )}
            {/* Pagination */}
            <div className="flex justify-center gap-4 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>

          {/* Mobile Cards */}
          <div className="lg:hidden" dir="rtl">
            {orderData ? (
              currentOrders.map((order: any) => (
                <div
                  key={order.id}
                  className="shadow-md hover:md mt-16 transition-shadow duration-300 ease-in-out px-4 py-6 mb-0 rounded-lg border border-gray-200"
                >
                  <p className="text-md font-semibold text-gray-800 mb-3">
                    الجهاز: {getDeviceName(order.DeviceId)}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">الباقة:</span>{" "}
                    <span>{order.amount}$ / شهرية</span>
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      رقم الهاتف:
                    </span>{" "}
                    {order.phone}
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      حالة الطلب:
                    </span>{" "}
                    <span
                      className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                        order.status === "pendding"
                          ? "bg-red-100 text-red-800"
                          : "bg-blue-100 text-blue-800"
                      }`}
                    >
                      {order.status === "pendding" ? "قيد الانتظار" : "مفعل"}
                    </span>
                  </p>
                  <p className="text-sm text-gray-700 mb-2">
                    <span className="font-medium text-gray-900">
                      تاريخ الطلب:
                    </span>{" "}
                    {new Intl.DateTimeFormat("ar-EG", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(new Date(order.created_at))}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-600">No Data</p>
            )}

            {/* Pagination for Mobile */}
            <div className="flex justify-center gap-5 items-center p-4">
              <button
                onClick={handlePreviousPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === 1}
              >
                <FaArrowLeft />
              </button>
              <span className="text-gray-500">
                صفحة {currentPage} من {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
                disabled={currentPage === totalPages}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
