import React, { useContext, useState } from "react";
import { FiEdit, FiPlus } from "react-icons/fi";
import { ScheduleContext } from "../../../../context/messages/ScheduleContext";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const CompaignMessage: React.FC = () => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const { schedules, loading, scheduled } = context;

  // State for pagination
  // console.log(schedules?.data)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Calculate index for pagination
  const indexOfLastRow = currentPage * rowsPerPage;

  // console.log(schedules?.Schedules)

  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentSchedules =
  //   schedules?.data?.slice(indexOfFirstRow, indexOfLastRow) || [];
  const currentSchedules =
  schedules?.Schedules?.slice(indexOfFirstRow, indexOfLastRow) || [];
  const deliveredSchedule = scheduled?.deliveredSchedule;
  const totalSchedule = scheduled?.totalSchedule;
  const pendingSchedule = scheduled?.pendingSchedule;
  const failedSchedule = scheduled?.failedSchedule;

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setRowsPerPage(value);
    setCurrentPage(1);
  };
  // Calculate total pages
  const totalPages = Math.ceil((schedules?.Schedules?.length || 0) / rowsPerPage);

  // Loading state
  if (loading) {
    return <div className="max:w-6xl py-12 px-16">Loading schedules...</div>;
  }

  // Empty state
  if (!schedules || schedules?.Schedules?.length === 0) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-16">
        <div className="flex justify-between px-4 py-2">
          <Link to={"/sendcampaignsms"}>
            <button className="flex items-center px-3 py-2 border border-indigo-400 rounded-md text-blue-500">
              <FiPlus className="w-6 h-6 mr-2" />
              اضافة
            </button>
          </Link>
        </div>
        <div>No schedules available</div>
      </div>
    );
  }
  // console.log(currentSchedules);
  return (
    <div dir="rtl" className="mx-2 overflow-x-auto scrollbar-light mt-12">
      <div className="flex justify-between px-2 py-2">
        <Link to={"/sendcampaignsms"}>
          <button className="flex items-center px-2 py-2 border border-indigo-400 rounded-md text-blue-500">
            <FiPlus className="w-6 h-6 mr-2" />
            اضافة
          </button>
        </Link>
      </div>
      <div className="sm:flex gap-2 grid-cols-1 px-2 space-y-1">
        <p className="border border-gray-200 rounded-md p-1">
          مجموع الرسائل:{" "}
          <span className="text-green-600 font-semibold">{totalSchedule}</span>
        </p>
        <p className="border border-gray-200 rounded-md p-1">
          المستلمة:{" "}
          <span className="text-green-600 font-semibold">
            {deliveredSchedule}
          </span>
        </p>
        <p className="border border-gray-200 rounded-md p-1">
          قيد الانتظار:{" "}
          <span className="text-green-600 font-semibold">
            {pendingSchedule}
          </span>
        </p>
        <p className="border border-gray-200 rounded-md p-1">
          الفاشلة:{" "}
          <span className="text-green-600 font-semibold">{failedSchedule}</span>
        </p>
      </div>
      <table className="w-[99%] bg-white">
        <thead className="items-start">
          <tr>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              العنوان
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              الرسالة
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              الجهاز
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              جهات الارسال
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              تاريخ الارسال
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              الحالة
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              operations
            </th>
          </tr>
        </thead>

        <tbody dir="rtl" className="overflow-y-auto scrollbar-light">
          {currentSchedules.map((schedule) => (
              <tr key={schedule.id}>
                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  <strong>{schedule.title}</strong>
                </td>
                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  <span>{schedule.message}</span>
                  {schedule.body && JSON.parse(schedule.body)?.image?.url && (
                    <span>
                      <img
                        src={JSON.parse(schedule.body)?.image?.url}
                        alt={
                          JSON.parse(schedule.body)?.caption ||
                          "Related content"
                        }
                        className="h-12 bg-cover mt-2"
                      />
                      <br />
                    </span>
                  )}
                  {schedule.file && (
                    <span className="text-green-600 font-semibold">
                      <br />
                      <a
                        href={schedule.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {schedule.file.split("/").pop()}
                      </a>
                    </span>
                  )}
                </td>
                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  {schedule.device.name}
                </td>
                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  {schedule?.contacts?.map((contact) => (
                    <div key={contact.id}>{contact.name} </div>
                  ))}
                </td>
                <td className="border-t text-xs border-gray-200 px-2 py-2">
                  {new Intl.DateTimeFormat("ar-EG", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(schedule.date))}{" "}
                  -
                  {new Intl.DateTimeFormat("ar-EG", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }).format(new Date(`${schedule.date} ${schedule.time}`))}
                </td>
                <td className="border-t cursor-pointer border-gray-200 px-2 py-2 whitespace-nowrap text-xs">
                  <span
                    className={`inline-flex px-2 py-1 text-xs font-semibold leading-5 rounded-full ${
                      schedule.status === "delivered"
                        ? "bg-blue-100 text-blue-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {schedule.status === "delivered" ? "مرسلة" : "قيد الانتظار"}
                  </span>
                </td>
                <td className="border-t cursor-pointer text-center text-xs border-gray-200 py-2 px-2">
                  <Link
                    title={`${schedule.message}`}
                    className="text-blue-400 "
                    to={`/updatecampaign/${schedule.id}`}
                  >
                    <FiEdit size={25} className="text-[#2a66b1]" />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div className="flex justify-start items-center gap-4 px-4 py-2">
        <div>
          {" "}
          {/* Rows per page selection */}
          <select
            value={rowsPerPage}
            onChange={handleItemsPerPageChange}
            className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
          >
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex p-2 justify-center gap-4 items-center ">
          <button
            onClick={() =>
              setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
            }
            disabled={currentPage === 1}
            className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
          >
            <FaArrowLeft />
          </button>
          <span className="self-center text-gray-700">
            الصفحة {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
            className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompaignMessage;
