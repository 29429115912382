// src/components/TextComponent.tsx
import React from "react";
import RichTextEditor from "./RichTextEditor";
import { AiFillFileText } from "react-icons/ai";

const TextComponent: React.FC = () => {

  return (
    <div className="justify-end h-screen gap-3 container mt-14">
        <div className="flex justify-end mx-16 text-lg mt-8">
        <span>ارسال رسائل نصية</span>
        <AiFillFileText size={25} className="text-cyan-600"/>
        </div>

      <RichTextEditor />
    </div>
  );
};

export default TextComponent;
