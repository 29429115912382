import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { rolePrivileges } = useContext(AuthContext);

  return rolePrivileges ===3 ? element : <Navigate to="/dashboard" replace />;
};

export default ProtectedRoute;
