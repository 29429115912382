import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import axios from "axios";
import { DeviceMasterContext } from "../devices/DeviceMasterContext";
import { AuthContext } from "../AuthContext";

interface GroupsContextType {
  GroupsData: any[] | null;
  loading: boolean;
}

export const GroupsContext = createContext<GroupsContextType>({
  GroupsData: null,
  loading: false,
});

const GroupsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { deviceData } = useContext(DeviceMasterContext);
  const [GroupsData, setGroupsData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { userId } = useContext(AuthContext);

  const fetchGroupMaster = useCallback(async () => {
    // Ensure deviceData and userId are not null or undefined
    if (!deviceData || !deviceData.data || !userId) return [];
    const authenticatedDevices = deviceData.data.filter(
      (device: any) =>
        device.status === "AUTHENTICATED" && device.id_users === userId
    );

    if (authenticatedDevices.length === 0) {
      console.log("No authenticated devices found");
      return;
    }

    setLoading(true);
    try {
      const allGroupssData = await Promise.all(
        authenticatedDevices.map(async (device: any) => {
          const { deviceId, Token: deviceToken } = device;
          try {
            const response = await axios.post(
              "https://whats1.sky.sky4system.com/api/group/GetGroupList",
              { deviceId, deviceToken }
            );
            // console.log(response.data);
            return response.data;
          } catch (error) {
            console.log(`Failed to fetch groups for device ${deviceId}, error`);
            return null;
          }
        })
      );

      const validGroupsData = allGroupssData.filter((data) => data !== null);
      // console.log(validGroupsData);
      setGroupsData(validGroupsData.flat());
    } catch (error) {
      console.log("Failed to fetch Groups data");
    } finally {
      setLoading(false);
    }
  }, [deviceData, userId]);

  useEffect(() => {
    fetchGroupMaster();
  }, [fetchGroupMaster]);

  return (
    <GroupsContext.Provider value={{ GroupsData, loading }}>
      {children}
    </GroupsContext.Provider>
  );
};

export default GroupsProvider;
