import React  from "react";
// import { FaTimes } from "react-icons/fa";

interface NotificationProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}



const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
  const bgColor = type === "success" ? "bg-green-500" : "bg-red-500";
  const textColor = type === "success" ? "text-green-50" : "text-red-100";

  return (
    <div
      className={`fixed top-4 right-4 p-4 px-4 rounded-lg shadow-lg ${bgColor} ${textColor} flex items-center space-x-6`}
    >
      <p>{message}</p>
      {/* <button onClick={onClose} className="text-left flex-1 text-lg">
        <FaTimes />
      </button> */}
    </div>
  );
};

export default Notification;
