/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { motion } from "framer-motion";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

const ContactUs: React.FC = () => {
  return (
    <div className="p-8 bg-white dark:bg-gray-700  text-gray-700  dark:text-gray-300 rounded-lg mb-12 max-w-4xl mx-auto" dir="rtl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-semibold dark:bg-gray-700 text-gray-700 dark:text-gray-300 mb-3">
          الأوائل لتقنية المعلومات
        </h1>
        <p className="text-xl dark:bg-gray-700 text-gray-700 dark:text-gray-300">
          الاوائل لتقنية المعلومات وخدمة الرسائل SMS
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="flex items-center gap-2 p-4">
          <FaMapMarkerAlt className="text-[#30aaf0] text-2xl" />
          <a href="https://maps.app.goo.gl/8BRtLY7n7rmWXizz7" className="text-base hover:text-blue-400 dark:bg-gray-800 text-gray-700 dark:text-gray-300">
            اليمن صنعاء - شارع مقديشو صخر سابقاً
          </a>
        </div>
        <div className="flex items-center gap-2 p-4">
          <FaPhone className="text-[#30aaf0] text-2xl" />
          <a href="tel:+9967777288199" className="text-base hover:text-blue-400 dark:bg-gray-800 text-gray-700 dark:text-gray-300">
            967777288199{" "}
          </a>{" "}
          - <a href="tel:+9671535960" className="dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:text-blue-400">009671535960</a>
        </div>
        <div className="flex items-center gap-2 p-4">
          <FaEnvelope className="text-[#30aaf0] text-2xl" />
          <a href="mailto:info@alawael.com" className="text-base dark:bg-gray-800 text-gray-700 dark:text-gray-300">info@alawael.com</a>
        </div>
        <div className="flex items-center gap-6 justify-center md:justify-start">
          <a
            href="https://www.facebook.com/alawael.for.info?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-blue-800 transition-colors"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-blue-800 transition-colors"
          >
            <BsTwitterX />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#30aaf0] text-3xl hover:text-blue-800 transition-colors"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>

      <div className="w-full h-80 bg-gray-200 rounded-lg overflow-hidden">
        <iframe
          className="w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1923.8496354663218!2d44.19513982479212!3d15.338569758659341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603db00232bf60b%3A0x9a2622d7cd24367d!2z2LTYsdmD2Kkg2KfZhNin2YjYp9im2YQg2YTYqtmC2YbZitipINin2YTZhdi52YTZiNmF2KfYqg!5e0!3m2!1sen!2s!4v1721789264782!5m2!1sen!2s"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
