// src/components/CompaignComponent.tsx
import React from "react";
import CompaignMessage from "./CompaignMessage";

const CompaignComponent: React.FC = () => {

  return (
    <div className="justify-start container mt-14">
      <CompaignMessage />
    </div>
  );
};

export default CompaignComponent;
