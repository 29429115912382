import React, { useCallback, useContext, useMemo, useState } from "react";
import Select, { MultiValue } from "react-select";
import { GroupsContext } from "../../../../context/contactsgroups/GroupsContext";
import "./css/GroupsSelect.css";
import colourStyles from "./colourStyles";

interface OptionType {
  value: string;
  label: string;
  groupDetails?: any;
}

interface GroupsSelectProps {
  onGroupsSelect: (selectedGroupIds: string[]) => void;
  selectedOptions: string[];
  selectedDeviceId: any | null;
}

const GroupsSelect: React.FC<GroupsSelectProps> = ({
  onGroupsSelect,
  selectedOptions = [],
  selectedDeviceId,
}) => {

  const { GroupsData } = useContext(GroupsContext);
  
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const options = useMemo(() => {
    if (!GroupsData || !Array.isArray(GroupsData)) return [];

    // console.log(GroupsData);

    // Flattening the nested data and filtering valid contacts
    return GroupsData.flatMap((item: any) => item.data.data)
      .filter((group: any) => 
        group && group.sessionId && group.sessionId === selectedDeviceId
      )
      .map((group: any) => {
        const numericId = group.id
        return {
          value: numericId,
          label: group.name || "لا يوجد مجموعات",
          groupDetails: group,
        };
      });
  }, [GroupsData,selectedDeviceId]);

  const handleChange = useCallback(
    (newValue: MultiValue<OptionType>) => {
      const selected = newValue as OptionType[];
      const selectedGroupIds = selected.map((option) => option.value);
      onGroupsSelect(selectedGroupIds);
    },
    [onGroupsSelect]
  );

  // Handle "Select All" functionality
  const handleSelectAllChange = useCallback(() => {
    if (selectAll) {
      onGroupsSelect([]);
    } else {
      const allGroupIds = options.map((option) => option.value);
      onGroupsSelect(allGroupIds);
    }
    setSelectAll(!selectAll);
  }, [onGroupsSelect, options, selectAll]);

  const customSelectAllOption = () => (
    <div className="select-all-option">
      <input
        type="checkbox"
        checked={selectAll}
        onChange={handleSelectAllChange}
        id="select-all"
      />
      {/* <label htmlFor="select-all" className="ml-2">اختار الكل</label> */}
    </div>
  );

  return (
    <div className="mt-8">
      <Select
        options={options}
        isMulti
        className="border border-blue-500"
        onChange={handleChange}
        value={options.filter(
          (option) =>
            Array.isArray(selectedOptions) &&
            selectedOptions.includes(option.value)
        )}
        placeholder="...اختار المجموعات "
        components={{ DropdownIndicator: customSelectAllOption }}
        classNamePrefix="react-select"
        closeMenuOnSelect={false}
        styles={colourStyles}
      />
    </div>
  );
};

export default GroupsSelect;
