import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
// import AnimatedCursor from "react-animated-cursor";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <AnimatedCursor
      innerSize={8}
      outerSize={30}
      color="193, 11, 111"
      outerScale={1.5}
      clickables={[
        "a",
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        "label[for]",
        "select",
        "textarea",
        "button",
        ".link",
      ]}
    /> */}
    <App />
  </React.StrictMode>
);

reportWebVitals();
