import React, { useContext, useState } from "react";
import { FiPlus, FiSearch } from "react-icons/fi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GroupContactContext } from "../../../../context/contactsgroups/GroupContactContext";
import { BsCardList } from "react-icons/bs";

const GroupContact: React.FC = () => {
  const context = useContext(GroupContactContext);

  if (!context) {
    throw new Error("useContext must be used within a ScheduleProvider");
  }

  const { groupcon, loading } = context;

  // State for pagination
  // console.log(groupcon?.data)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Calculate index for pagination
  const indexOfLastRow = currentPage * rowsPerPage;

  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const [filterText, setFilterText] = useState<string>("");

  const filteredGroups =
    groupcon?.groups?.filter((group) =>
      group.name.toLowerCase().includes(filterText.toLowerCase())
    ) || [];

  // const currentgroupcon =
  //   groupcon?.groups?.slice(indexOfFirstRow, indexOfLastRow) || [];

  const currentgroupcon = filteredGroups.slice(indexOfFirstRow, indexOfLastRow);

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setRowsPerPage(value);
    setCurrentPage(1);
  };
  // Calculate total pages
  const totalPages = Math.ceil((groupcon?.groups?.length || 0) / rowsPerPage);

  // Loading state
  if (loading) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-16">
        <div className="flex justify-between px-4 py-2">
          <div className="max:w-6xl py-12 px-16">Loading groups...</div>
        </div>
      </div>
    );
  }

  // Empty state
  if (!groupcon || groupcon?.groups?.length === 0) {
    return (
      <div className="max:w-7xl border border-gray-200 px-16 rounded-3xl p-2 grid justify-center py-12 mx-2 sm:mx-16">
        <div className="flex justify-between px-4 py-2">
          <Link to={"/creatnewgroup"}>
            <button className="flex items-center px-3 py-2 border border-indigo-400 rounded-md text-blue-500">
              <FiPlus className="w-6 h-6 mr-2" />
              اضافة
            </button>
          </Link>
        </div>
        <div>No groupcon available</div>
      </div>
    );
  }
  // console.log(currentgroupcon);
  return (
    <div dir="rtl" className="mx-2 overflow-x-auto scrollbar-light mt-12">
      <div className="flex justify-start gap-6 px-2 py-2">
        <Link to={"/creatnewgroup"}>
          <button className="flex items-center px-2 py-2 border border-indigo-400 rounded-md text-blue-500">
            <FiPlus className="w-6 h-6 mr-2" />
            اضافة
          </button>
        </Link>
        {/* <button className="text-blue-500" onClick={fetchGroups}>
          {" "}
          <FiRefreshCcw size={20} />{" "}
        </button> */}
      </div>
      <div className="my-3 px-2">
        <div className="relative inline-block">
          <FiSearch className="absolute top-3 left-3 text-gray-500" size={20} />
          <input
            type="text"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="pl-10 p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="sm:flex gap-2 grid-cols-1 px-2 space-y-1"></div>
      <table className="w-[99%] bg-white">
        <thead className="items-start">
          <tr>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              اسم المجموعة
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              عدد جهات الاتصال
            </th>
            <th className="py-2 text-right text-xs text-gray-500 uppercase tracking-wider px-2">
              operations
            </th>
          </tr>
        </thead>

        <tbody dir="rtl" className="overflow-y-auto scrollbar-light">
          {currentgroupcon.map((group) => (
            <tr key={group.id}>
              <td className="border-t text-xs border-gray-200 px-2 py-2">
                <strong>{group.name}</strong>
              </td>
              <td className="border-t text-xs border-gray-200 px-2 py-2">
                {group.groupcontacts_count}
              </td>
              {/* <td className="border-t text-xs border-gray-200 px-2 py-2">
                  {group?.contacts?.map((contact) => (
                    <div key={contact.id}>{contact.name} </div>
                  ))}
                </td> */}
              <td className="border-t cursor-pointer text-center text-xs border-gray-200 py-2 px-2">
                <Link
                  title={`${group.name}`}
                  className="text-blue-400 "
                  to={`/groupcontact/${group.group_id}`}
                >
                  <BsCardList size={20} className="text-[#2a66b1]" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div className="flex justify-start items-center gap-4 px-2 py-2">
        <div>
          {" "}
          {/* Rows per page selection */}
          <select
            value={rowsPerPage}
            onChange={handleItemsPerPageChange}
            className="text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-300 focus:border-gray-300 p-1 dark:focus:border-gray-400"
          >
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex p-2 justify-center gap-4 items-center ">
          <button
            onClick={() =>
              setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
            }
            disabled={currentPage === 1}
            className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
          >
            <FaArrowLeft />
          </button>
          <span className="self-center text-gray-700">
            الصفحة {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
            className="p-2 bg-blue-400 text-white rounded-full disabled:opacity-50"
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupContact;
