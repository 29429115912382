import { AiFillContacts } from "react-icons/ai";
import GroupContact from "./GroupContact";

const GroupContactComponent: React.FC = () => {
  return (
    <div className="border-b h-screen justify-end gap-3 container rounded-md mt-14">
      <div className="flex justify-end mx-2 text-lg mt-8">
        <div className="flex justify-end gap-2 rounded-md text-blue-500 font-bold p-1">
          <span>جهات الاتصال</span>
          <AiFillContacts size={25} className="text-cyan-600" />
        </div>
      </div>
      <GroupContact />
    </div>
  );
};

export default GroupContactComponent;
